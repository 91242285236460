import { Box, Typography, useTheme } from "@material-ui/core";
import * as muiColors from "@material-ui/core/colors";
import { useIsInternalUser } from "infra/auth/useIsInternalUser";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";

import {
  ENV_DESIGNATION_COLOR,
  ENV_DESIGNATION_INTERNAL,
  ENV_DESIGNATION_MESSAGE,
  ENV_DESIGNATION_NAME,
} from "../infra/config";

export function EnvDesignationsRoute({ location }: RouteComponentProps) {
  const position = location.pathname.startsWith("/tools") ? "bottom" : "top";

  const isInternalUser = useIsInternalUser();

  return (
    <EnvDesignations position={position} isInternalUser={isInternalUser} />
  );
}

type EnvDesignationsProps = {
  position: StickerProps["position"];
  isInternalUser?: boolean;
};

export function EnvDesignations({
  position,
  isInternalUser,
}: EnvDesignationsProps) {
  const color = ENV_DESIGNATION_COLOR;
  const name = ENV_DESIGNATION_NAME;
  const message = ENV_DESIGNATION_MESSAGE;

  if (ENV_DESIGNATION_INTERNAL && !isInternalUser) {
    return null;
  }

  return (
    <>
      {name && (
        <Helmet>
          <title>{`[${name}] Memora Health`}</title>
        </Helmet>
      )}
      <Sticker
        position={position}
        color={color}
        name={name}
        message={message}
      />
    </>
  );
}

type StickerProps = {
  position: "top" | "bottom";
  color?: string;
  name?: string;
  message?: string;
};

function Sticker({ position, color, name, message }: StickerProps) {
  const theme = useTheme();

  if (!name || !color) {
    return null;
  }

  const containerProps =
    position === "top"
      ? {
          top: 0,
        }
      : { bottom: 0 };
  const chipProps =
    position === "top"
      ? {
          borderTop: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }
      : {
          borderBottom: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        };

  const envColor = _.get(muiColors, `${color}.800`, muiColors.grey["400"]);
  const envTextColor = theme.palette.getContrastText(envColor);
  const messageColor = _.get(muiColors, `${color}.600`, muiColors.grey["100"]);
  const messageTextColor = theme.palette.getContrastText(messageColor);

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        zIndex: 4000,
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
        pointerEvents: "none",
        ...containerProps,
      }}
    >
      <Box
        border="1px solid"
        borderColor="grey.600"
        bgcolor={messageColor}
        color={messageTextColor}
        borderRadius={10}
        style={{
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          overflow: "hidden",
          ...chipProps,
        }}
      >
        <Box
          py={0.25}
          px={1}
          component="span"
          bgcolor={envColor}
          style={{
            textTransform: "uppercase",
          }}
        >
          <Typography
            style={{
              color: envTextColor,
              fontWeight: 800,
              fontSize: "1.1em",
            }}
          >
            {name}
          </Typography>
        </Box>
        {message && (
          <Box component="span" py={0.25} px={1}>
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "0.95em",
              }}
            >
              {message}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}
